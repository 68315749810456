import { navLinks } from "@/router/navLinks/";

const desktopHeader = [
  navLinks.WISHLIST,
  navLinks.ACCOUNT_RESPONSES,
  // navLinks.NOTIFICATIONS,
];

const desktopHeaderModalMenu = [
  // navLinks.PREMIUM,
  // navLinks.ACCOUNT_OFFERS,
  // navLinks.ACCOUNT_PROFILE,
  navLinks.LOGIN,
];

const desktopFooter = [
  navLinks.WISHLIST,
  navLinks.WISHLIST,
  navLinks.ACCOUNT_RESPONSES,
  navLinks.NOTIFICATIONS,
];

// Mobile links
const mobileDefault = [
  navLinks.LIST_NANNY,
  navLinks.WISHLIST,
  navLinks.ACCOUNT_RESPONSES,
  navLinks.NOTIFICATIONS,
  navLinks.MOBILE_MENU,
];

export const navLinksAnonymus = {
  desktopHeader,
  desktopHeaderModalMenu,
  desktopFooter,
  mobileDefault,
};
