import { navLinks } from "@/router/navLinks/";

const desktopHeader = [
  navLinks.ACCOUNT_APPLICATIONS,
  navLinks.ACCOUNT_RESPONSES,
  navLinks.NOTIFICATIONS,
];

const desktopHeaderWithPushToCreateApplication = [
  navLinks.PUSH_TO_CREATE_APPLICATION,
  // navLinks.ACCOUNT_APPLICATIONS,
  navLinks.NOTIFICATIONS,
];

const desktopHeaderModalMenu = [
  navLinks.ACCOUNT_PREMIUM,
  navLinks.ACCOUNT_APPLICATIONS,
  navLinks.ACCOUNT_PROFILE,
  navLinks.LOGOUT,
];

// const desktopFooter = [
//   navLinks.ACCOUNT_APPLICATIONS,
//   navLinks.NOTIFICATIONS,
//   navLinks.ACCOUNT_APPLICATIONS,
//   navLinks.ACCOUNT_PROFILE,
// ];

// Mobile links
const mobileDefault = [
  navLinks.LIST_NANNY,
  navLinks.ACCOUNT_APPLICATIONS,
  navLinks.ACCOUNT_RESPONSES,
  navLinks.NOTIFICATIONS,
  navLinks.MOBILE_MENU,
];

const mobileDefaultWithPushToCreateApplication = [
  navLinks.LIST_NANNY,
  navLinks.ACCOUNT_APPLICATIONS,
  navLinks.PUSH_TO_CREATE_APPLICATION,
  navLinks.NOTIFICATIONS,
  navLinks.MOBILE_MENU,
];

const mobileMenu = [
  navLinks.ACCOUNT_PREMIUM,
  navLinks.ACCOUNT_APPLICATIONS,
  navLinks.ACCOUNT_PROFILE,
  navLinks.LOGOUT,
];

export const navLinksContractor = {
  desktopHeader,
  desktopHeaderWithPushToCreateApplication,
  desktopHeaderModalMenu,
  // desktopFooter,
  mobileDefault,
  mobileDefaultWithPushToCreateApplication,
  mobileMenu,
};
