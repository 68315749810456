import {
  navLinksAnonymus,
  navLinksContractor,
  navLinksClient,
  navLinks as NAV_LINKS_DICT,
} from "@/router/navLinks";
import { useUserStore } from "@/stores/user";
import { computed, reactive } from "vue";

export default function useNavLinks() {
  const userStore = useUserStore();

  const navLinksDesktopHeader = computed(() => {
    const userType = userStore.userType;
    if (userType === "contractor") {
      // TODO: when more categories, rewrite it
      const applicationNanny = userStore.applications.nanny_id;
      if (applicationNanny === null) {
        return navLinksContractor.desktopHeaderWithPushToCreateApplication;
      }
      return navLinksContractor.desktopHeader;
    }

    if (userType === "client") {
      const offers = userStore.offers.nanny_ids;
      if (offers.length === 0) {
        return navLinksClient.desktopHeaderWithPushToCreateOffer;
      }
      return navLinksClient.desktopHeader;
    }

    return [];
  });

  const navLinksDesktopFooter = computed(() => {
    // const userType = userStore.userType;
    // if (userType === "contractor") {
    //   return navLinksContractor.desktopFooter;
    // }

    // if (userType === "client") {
    //   return navLinksClient.desktopFooter;
    // }

    return navLinksAnonymus.desktopFooter;
  });

  const navLinksDesktopHeaderModalMenu = computed(() => {
    const userType = userStore.userType;
    if (userType === "contractor") {
      return navLinksContractor.desktopHeaderModalMenu;
    }

    if (userType === "client") {
      // TODO: must depend on condition 'if premium bought then add purchases to menu'
      const links = [...navLinksClient.desktopHeaderModalMenu];
      links.splice(1, 0, NAV_LINKS_DICT.ACCOUNT_PURCHASES);
      return links;
    }

    return [];
  });

  const navLinksMobileDefault = computed(() => {
    const userType = userStore.userType;
    if (userType === "contractor") {
      const applicationNanny = userStore.applications.nanny_id;
      if (applicationNanny === null) {
        return navLinksContractor.mobileDefaultWithPushToCreateApplication;
      }
      return navLinksContractor.mobileDefault;
    }

    if (userType === "client") {
      const offers = userStore.offers.nanny_ids;
      if (offers.length === 0) {
        return navLinksClient.mobileDefaultWithPushToCreateOffer;
      }
      return navLinksClient.mobileDefault;
    }

    return [];
  });

  const navLinksMobileMenu = computed(() => {
    const userType = userStore.userType;
    if (userType === "contractor") {
      return navLinksContractor.mobileMenu;
    }

    if (userType === "client") {
      return navLinksClient.mobileMenu;
    }

    return [];
  });

  const navLinks = reactive({
    desktopHeader: navLinksDesktopHeader,
    desktopHeaderModalMenu: navLinksDesktopHeaderModalMenu,
    desktopFooter: navLinksDesktopFooter,
    mobileDefault: navLinksMobileDefault,
    mobileMenu: navLinksMobileMenu,
  });

  return { navLinks };
}
