import {
  ROUTE_ACCOUNT_APPLICATIONS_NAME,
  ROUTE_ACCOUNT_OFFERS_NAME,
  ROUTE_ACCOUNT_PROFILE_NAME,
  ROUTE_ACCOUNT_RESPONSES_NAME,
  ROUTE_LIST_NANNY_NAME,
  ROUTE_MOBILE_MENU_NAME,
  ROUTE_LOGIN_START_NAME,
  ROUTE_WISHLIST_NAME,
  ROUTE_LOGOUT_NAME,
  ROUTE_ACCOUNT_PREMIUM_NAME,
  ROUTE_ACCOUNT_PURCHASES_NAME,
  ROUTE_NOTIFICATIONS_NAME,
} from "@/router/constants";
import { getRawAsyncComponent } from "@/helpers/getRawAsyncComponent";

const resolveImport = (iconComponentName) => {
  return import("@/components/Svg/Icons/" + iconComponentName);
};

class Link {
  constructor({ label, routeName, iconComponentName, type }) {
    this.label = label || "!!!";
    this.to = {
      name: routeName,
    };
    this.routeName = routeName;
    if (iconComponentName) {
      this.iconComponent = getRawAsyncComponent(
        resolveImport(iconComponentName)
      );
    }
    this.type = type || "link";
  }
}

class CtaLink extends Link {
  constructor(options) {
    super({ ...options, type: "cta" });
  }
}

class NotificationLink extends Link {
  constructor(options) {
    super({ ...options, type: "notification" });
  }
}

const navLinks = {
  PUSH_TO_CREATE_OFFER: new CtaLink({
    label: "Dodaj ogłoszenie",
    routeName: ROUTE_ACCOUNT_OFFERS_NAME,
    iconComponentName: "SvgIconsAddCircleCta24.vue",
  }),
  PUSH_TO_CREATE_APPLICATION: new CtaLink({
    label: "Wypełnij ankietę",
    routeName: ROUTE_ACCOUNT_APPLICATIONS_NAME,
    iconComponentName: "SvgIconsAddCircleCta24.vue",
  }),
  LIST_NANNY: new Link({
    iconComponentName: "SvgIconsSearch24.vue",
    routeName: ROUTE_LIST_NANNY_NAME,
  }),
  WISHLIST: new Link({
    label: "Ulubione",
    routeName: ROUTE_WISHLIST_NAME,
    iconComponentName: "SvgIconsWishlist24.vue",
  }),
  NOTIFICATIONS: new NotificationLink({
    label: "Powiadomenia",
    routeName: ROUTE_NOTIFICATIONS_NAME,
    iconComponentName: "SvgIconsNotifications24.vue",
  }),
  MOBILE_MENU: new Link({
    iconComponentName: "SvgIconsAccount24.vue",
    routeName: ROUTE_MOBILE_MENU_NAME,
  }),
  ACCOUNT_PREMIUM: new Link({
    label: "Premium",
    routeName: ROUTE_ACCOUNT_PREMIUM_NAME,
  }),
  ACCOUNT_PURCHASES: new Link({
    label: "Moje zakupy",
    routeName: ROUTE_ACCOUNT_PURCHASES_NAME,
  }),
  ACCOUNT_RESPONSES: new NotificationLink({
    label: "Zgłoszenia",
    routeName: ROUTE_ACCOUNT_RESPONSES_NAME,
    iconComponentName: "SvgIconsResponses24.vue",
  }),
  ACCOUNT_OFFERS: new Link({
    label: "Moje ogłoszenia",
    routeName: ROUTE_ACCOUNT_OFFERS_NAME,
  }),
  ACCOUNT_APPLICATIONS: new Link({
    label: "Moje ankiety",
    routeName: ROUTE_ACCOUNT_APPLICATIONS_NAME,
    iconComponentName: "SvgIconsAccountApplications24.vue",
  }),
  ACCOUNT_PROFILE: new Link({
    label: "Profil",
    routeName: ROUTE_ACCOUNT_PROFILE_NAME,
  }),
  LOGIN: new Link({
    label: "Zaloguj się!",
    routeName: ROUTE_LOGIN_START_NAME,
  }),
  LOGOUT: new Link({
    label: "Wyloguj się",
    routeName: ROUTE_LOGOUT_NAME,
  }),
};

export { navLinks };
