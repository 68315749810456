import { navLinks } from "@/router/navLinks/";

// Desktop links

const desktopHeader = [
  navLinks.WISHLIST,
  navLinks.ACCOUNT_RESPONSES,
  navLinks.NOTIFICATIONS,
];

const desktopHeaderWithPushToCreateOffer = [
  navLinks.PUSH_TO_CREATE_OFFER,
  navLinks.WISHLIST,
  navLinks.NOTIFICATIONS,
];

const desktopHeaderModalMenu = [
  navLinks.ACCOUNT_PREMIUM,
  navLinks.ACCOUNT_OFFERS,
  navLinks.ACCOUNT_PROFILE,
  navLinks.LOGOUT,
];

// const desktopFooter = [
//   navLinks.WISHLIST,
//   navLinks.ACCOUNT_RESPONSES,
//   navLinks.NOTIFICATIONS,
// ];

// Mobile links
const mobileDefault = [
  navLinks.LIST_NANNY,
  navLinks.WISHLIST,
  navLinks.ACCOUNT_RESPONSES,
  navLinks.NOTIFICATIONS,
  navLinks.MOBILE_MENU,
];

const mobileDefaultWithPushToCreateOffer = [
  navLinks.LIST_NANNY,
  navLinks.WISHLIST,
  navLinks.PUSH_TO_CREATE_OFFER,
  navLinks.NOTIFICATIONS,
  navLinks.MOBILE_MENU,
];

const mobileMenu = [
  navLinks.ACCOUNT_PREMIUM,
  navLinks.ACCOUNT_OFFERS,
  navLinks.ACCOUNT_PROFILE,
  navLinks.LOGOUT,
];

export const navLinksClient = {
  desktopHeader,
  desktopHeaderWithPushToCreateOffer,
  desktopHeaderModalMenu,
  // desktopFooter,
  mobileDefault,
  mobileDefaultWithPushToCreateOffer,
  mobileMenu,
};
